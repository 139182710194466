
<script>

	import { Router } from "@sveltech/routify";
  	import { routes } from "../.routify/routes";

</script>


<style  global>:global(.width-500) {
	width: 500px;
}

:global(.nav-container) {
	margin-top: 10px;
}

:global(.bottom-margin) {
	margin-bottom: 30px !important;
}

:global(.tabs.is-toggle) :global(li.is-active) :global(a) {
	background-color: #00c4a7 !important ;
	border-color: #00c4a7 !important;
	border-bottom-color: #00c4a7 !important;
}

:global(.flexcenter-override) {
	display:flex !important;
	justify-content: center !important;
}

:global(.dragndrop) {
	border: 3px dashed #00c4a7;
	width: 500px;
	height: 300px;
	display:flex;
	justify-content: center;
	align-items: center;
	color: #00c4a7;
}

:global(.dragndropenter) {
	border: 3px dashed #ffdd57;
	width: 500px;
	height: 300px;
	display:flex;
	justify-content: center;
	align-items: center;
	color: #ffdd57;	
}

:global(.smalltext) {
	font-size: 1rem;
}

/* tr:hover {
	background: #00c4a7;
	color: white;
} */

/*tr {
	overflow: hidden !important;
	white-space: nowrap !important; 
	text-overflow: ellipsis !important;	
}*/

:global(.crop) {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:global(.table-buttons-right) {
	text-align: right !important;
	width: 50px !important;
}

:global(.delete-margin-4px) {
	margin-top:4px;
	background-color:#ffdd57;
}

:global(td) :global(a) {
	color: inherit;
}

:global(.advanced-options) :global(::-webkit-input-placeholder) { /* Edge */
  color: #4a4a4a !important;
}

:global(.advanced-options) :global(:-ms-input-placeholder)  { /* Internet Explorer 10-11 */
  color: #4a4a4a !important;
}

:global(.advanced-options) :global(::placeholder)  {
  color: #4a4a4a !important;
}

:global(.margin-top-bottom) {
	margin-bottom: 10px;
	margin-top: 10px;
}

:global(.columns.no-margin-bottom) {
	margin-bottom: -1.5rem;
}

:global(.radio) {
	top: 3px;
}

:global(.radius-bottom-remove) {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;

}

:global(.radius-top-remove) {
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}</style>

<Router {routes} />





